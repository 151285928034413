import * as React from 'react';

/* eslint-disable-next-line */
export interface TextographyProps {
  children?: React.ReactNode;
  variant?:
    | 'mini'
    | 'small'
    | 'normal'
    | 'title'
    | 'subtitle'
    | 'header'
    | 'minor'
    | 'major';
  style?: React.CSSProperties;
  'data-testid'?: string;
  onClick?: () => void;
  className?: string;
  rep?: boolean;
}

export function Textography({
  variant = 'normal',
  className,
  rep,
  ...props
}: TextographyProps) {
  return (
    <div
      className={`text-${variant} ${className && className}`}
      style={{ fontFamily: rep && 'GlacialIndifference' }}
      {...props}
    >
      {props.children}
    </div>
  );
}

export default Textography;
