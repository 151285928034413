import * as React from 'react';
import Head from 'next/head';
import { config, dom } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = false;

export const HeadFont = (): JSX.Element => {
  return (
    <div>
      <Head>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Exo+2"
        />
        <style>{dom.css()}</style>
      </Head>
    </div>
  );
};
