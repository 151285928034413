/* eslint-disable */

import { ApolloClient, concat, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import router from 'next/router';
import { useMemo } from 'react';
import { localItemsVar } from './localStorage';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      if (message.includes('Session')) {
        router.push({
          pathname: '/',
          query: { reason: 'TIMED_OUT' },
        });
      }
    });
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

// const httpLink = new HttpLink({
//   uri: '/api', // Server URL (must be absolute)
//   credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
// });

let apolloClient;

function createApolloClient(url) {
  const httpLink = new HttpLink({
    uri: url, // Server URL (must be absolute)
    credentials: 'include',
    // credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: concat(errorLink, httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            localItems: {
              read() {
                return localItemsVar();
              },
            },
          },
        },
      },
    }),
  });
}

export function initializeApollo(initialState = null, url = '/api') {
  const _apolloClient = apolloClient ?? createApolloClient(url);
  // const _apolloClient = createApolloClient();
  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState, url = '/api') {
  const store = useMemo(() => initializeApollo(initialState, url), [
    initialState,
  ]);
  return store;
}
