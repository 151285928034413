import { makeVar } from '@apollo/client';
import { user_role } from '@prisma/client';

export const FS_LOCAL_DATA = '@FS:LOCAL_DATA';

export const localItemsVar = makeVar({});

export interface LocalUserProps {
  token?: string;
  name?: string;
  email?: string;
  team?: string;
  lastLogin?: string;
  role?: string | user_role;
  salesRepInfo?: {
    id?: number;
  };
  salesManagerInfo?: {
    id?: number;
  };
  setterInfo?: {
    id?: number;
  };
  repInfo?: {
    id?: number;
  };
  profilePic?: string;
  officeName?: string
}

export interface LocalItemsProps extends LocalUserProps {
  altUser?: LocalUserProps;
}

export const clearLocalStorageInfo = (): void => {
  if (process.browser) {
    localStorage.removeItem(FS_LOCAL_DATA);
  }
};

export const setLocalStorageInfo = (data: LocalItemsProps): void => {
  if (process.browser) {
    localStorage.setItem(FS_LOCAL_DATA, JSON.stringify(data));
  }
};

export const getLocalStorageInfo = (): LocalItemsProps => {
  if (process.browser) {
    const localInfo = localStorage.getItem(FS_LOCAL_DATA);
    if (localInfo) {
      return JSON.parse(localInfo);
    }
  }

  return {
    token: '',
  };
};
