import { HeadFont } from '@fluentsolar/shared-web/feature-HeadFont';
import { Textography } from '@fluentsolar/shared-web/Textography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faAward,
  faBars,
  faClipboardList,
  faExclamationTriangle,
  faProjectDiagram,
  faSitemap,
  faTable,
  faToolbox,
  faUsers,
  faStop,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import * as React from 'react';
import { animated, useSpring } from 'react-spring';

export function Dashboard(props: {
  children?: React.ReactNode;
  page?: string;
}) {
  const [open, setOpen] = React.useState(false);

  const animProps = useSpring({ left: open ? '0vw' : '-100vw' });
  const onClose = () => {
    setOpen(false);
  };
  const onMenuPress = () => {
    setOpen(true);
  };

  return (
    <div className="w-full min-h-screen theme-general theme-dark bg-primary text-normal">
      <HeadFont />
      <div className="flex flex-row">
        <animated.div
          className="bg-primary"
          style={{
            minHeight: '100vh',
            width: '100%',
            // background: `linear-gradient(45deg, ${DASHBOARD_LIGHT}, ${DASHBOARD_DARK})`,
            position: 'absolute',

            zIndex: 10,
            ...animProps,
          }}
        >
          <SideMenu page={props.page} onClose={onClose} />
        </animated.div>
        <div className="w-full min-h-screen bg-secondary">
          <div className="flex flex-row w-full p-4 border-b border-gray-500 bg-primary">
            <div className="flex-grow">
              <Textography>App Bar</Textography>
            </div>
            <div>
              <FontAwesomeIcon
                icon={faBars}
                color="white"
                style={{ width: 20, height: 20 }}
                onClick={onMenuPress}
              />
            </div>
          </div>
          <div className="p-4">{props.children}</div>
        </div>
      </div>
    </div>
  );
}

function SideMenu(props: { page: string; onClose?: () => void }) {
  const router = useRouter();

  return (
    <div
      style={{ width: '100%' }}
      className="min-h-screen border-r border-gray-500"
    >
      <div className="flex items-center p-4">
        <div className="flex flex-grow">
          <Textography>Fluent Solar</Textography>
        </div>
        <FontAwesomeIcon
          icon={faStop}
          color="white"
          style={{ width: 20, height: 20 }}
          onClick={props.onClose}
        />
      </div>
      <div className="p-4">
        {[
          {
            id: 1,
            text: 'Dashboard',
            icon: faTable,
            href: '/dashboard/home',
          },
          {
            id: 2,
            text: 'Podio Integration',
            icon: faSitemap,
            href: '/dashboard/podiointegration',
          },
          {
            id: 3,
            text: 'Podio Tools',
            icon: faToolbox,
            href: '/dashboard/podiotools',
          },
          {
            id: 4,
            text: 'Webhooks',
            icon: faProjectDiagram,
            href: '/dashboard/webhooks',
          },
          { id: 5, text: 'Users', icon: faUsers, href: '/dashboard/users' },
          {
            id: 6,
            text: 'Errors',
            icon: faExclamationTriangle,
            href: '/dashboard/errors',
          },
          {
            id: 7,
            text: 'Logs',
            icon: faClipboardList,
            href: '/dashboard/logs',
          },
          {
            id: 8,
            text: 'Tournaments',
            icon: faAward,
            href: '/dashboard/tournaments',
          },
        ].map((nav) => (
          <SideNavButton
            key={nav.id}
            active={props.page === nav.href}
            onClick={() => {
              router.push(nav.href);
              props.onClose();
            }}
            {...nav}
          />
        ))}
      </div>
    </div>
  );
}

const SideNavButton = (props: {
  active?: boolean;
  text?: string;
  icon?: IconProp;
  href?: string;
  onClick?: () => void;
}) => {
  const router = useRouter();

  return (
    <div
      className={`flex flex-row px-3 py-4 space-x-3 rounded-lg cursor-pointer hover:bg-green-800 ${
        props.active && 'bg-green-500'
      }`}
      onClick={props.onClick}
    >
      <FontAwesomeIcon icon={props.icon} style={{ width: 20, height: 20 }} />
      <Textography>{props.text}</Textography>
    </div>
  );
};
